import React from "react";
import "../assets/css/ReceiptOptions.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";

class ReceiptOptions extends React.Component {
  renderButtons = () => {
    const receiptTypes = ["Charitable", "Business", "Acknowledge"];

    return receiptTypes.map((type, index) => (
      <li key={index}>
        <button
          type="button"
          className={`form-btn ${
            this.props.selectedType === type ? "active" : "inactive"
          }`}
          onClick={() => this.props.handleTypeClick(type)}
        >
          <span className="button-content">
            <FontAwesomeIcon
              icon={
                this.props.selectedType === type ? faSquareCheck : faSquare
              }
              className="checkbox-icon icon-lg"
            />
            &nbsp;{type}
          </span>
        </button>
      </li>
    ));
  };

  render() {
    return (
      <div className="causes-area causes-single-items receipt-options">
        <div className="col-md-12 item">
          <ul className="group">{this.renderButtons()}</ul>
        </div>
      </div>
    );
  }
}

export default ReceiptOptions;
