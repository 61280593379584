import React from "react";
import Form from "react-bootstrap/Form";
import '../assets/css/ClassificationForm.css';


class ClassificationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { handleInputChange, formData, appealOptions, campaignOptions, fundOptions, packageOptions } = this.props;
    const dropdownStyle = { textAlign: "center" };

    return (
      <div className="classification">

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="fund">Fund</label>
            <div className="dropdown input-group">
              <Form.Control
                id="fund"
                as="select"
                value={formData.fund}
                onChange={handleInputChange}
                style={dropdownStyle}
              >
                <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Choose a fund &#x2015;
                </option>
                {fundOptions.map((fund) => (
                  <>
                    <option key={fund.id} value={fund}>
                      {fund.option_name}
                    </option>
                  </>
                ))}
              </Form.Control>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="appeal">Appeal</label>
            <div className="dropdown input-group">
              <Form.Control
                id="appeal"
                as="select"
                value={formData.appeal}
                onChange={handleInputChange}
                style={dropdownStyle}
              >
                <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Choose an appeal &#x2015;
                </option>
                {appealOptions.map((appeal) => (
                  <>
                    <option key={appeal.id} value={appeal}>
                      {appeal.option_name}
                    </option>
                  </>
                ))}
              </Form.Control>
            </div>
          </div>
        </div>


        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="campaign">Campaign</label>
            <div className="dropdown input-group">
              <Form.Control
                id="campaign"
                as="select"
                value={formData.campaign}
                onChange={handleInputChange}
                style={dropdownStyle}
              >
                <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Choose a campaign &#x2015;
                </option>
                {campaignOptions.map((campaign) => (
                  <>
                    <option key={campaign.id} value={campaign}>
                      {campaign.option_name}
                    </option>
                  </>
                ))}
              </Form.Control>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="package">Package</label>
            <div className="dropdown input-group">
              <Form.Control
                id="package"
                as="select"
                value={formData.package}
                onChange={handleInputChange}
                style={dropdownStyle}
              >
                <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Choose a package &#x2015;
                </option>
                {packageOptions.map((myPackage) => (
                  <>
                    <option key={myPackage.id} value={myPackage}>
                      {myPackage.option_name}
                    </option>
                  </>
                ))}
              </Form.Control>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default ClassificationForm;
