import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

import { Route, Routes } from "react-router-dom";
import DonationComponent from './components/DonationComponent'
import VirtualTerminalComponent from './components/VirtualTerminalComponent'
import MarleyDonationComponent from './components/MarleyDonationComponent';


function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<VirtualTerminalComponent />} />
        <Route path="/marley" element={<VirtualTerminalComponent />} />
        <Route path="/marley/monthly" element={<MarleyDonationComponent />} />
        <Route path="/nathania" element={<VirtualTerminalComponent />} />
        <Route path="/marni" element={<VirtualTerminalComponent />} />
        <Route path="/cindy" element={<VirtualTerminalComponent />} />
        <Route path="/chantelle" element={<VirtualTerminalComponent />} />
        <Route path="/preview/virtualterminal" element={<VirtualTerminalComponent />} />
        <Route path="/preview/virtualterminal/marley" element={<VirtualTerminalComponent />} />
        <Route path="/preview/virtualterminal/nathania" element={<VirtualTerminalComponent />} />
        <Route path="/preview/virtualterminal/marni" element={<VirtualTerminalComponent />} />
        <Route path="/preview/virtualterminal/cindy" element={<VirtualTerminalComponent />} />
        <Route path="/preview/virtualterminal/chantelle" element={<VirtualTerminalComponent />} />
      </Routes>
    </div>
  );
}

export default App;
